import React from "react"
import BlogLayout from "../../components/Blog/BlogLayout"
import BlogOne from "../../components/Blog/BlogsList/BlogOne"
const BlogContent = () => {
  return (
    <BlogLayout>
      <BlogOne />
    </BlogLayout>
  )
}

export default BlogContent
